import './App.css';

function App() {
  return (
    <div className="App">
        <p>
          Hupulaanen on apulainen, eli kun teet jotain työtä ja joku auttava tyyppi siinä häärää, niin juuri se tyyppi on hupulaanen,
          ainaki näin täällä Etelä-Pohjanmaalla.
        </p>
    </div>
  );
}

export default App;
